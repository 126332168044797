import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Typography",
  "sortOrder": 6.5
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Typography`}</h1>
    <p className="p-large">Typography tokens give you the flexibility to create any font style you'd like. </p>
    <blockquote>
      <p parentName="blockquote">{`We've scoped our tokens down into a smaller collection you can use. See our `}<a parentName="p" {...{
          "href": "/components/text"
        }}>{`text component`}</a>{` for using text styles our sportsbook product.`}</p>
    </blockquote>
    <TokenTable tokenGroup="Typography" onlyShow="FontFamily" showTitle={true} useMultiple={true} mdxType="TokenTable" />
    <TokenTable tokenGroup="Typography" onlyShow="FontSize" showTitle={true} useMultiple={true} hideSwitcher={true} mdxType="TokenTable" />
    <TokenTable tokenGroup="Typography" onlyShow="FontWeight" showTitle={true} useMultiple={true} hideSwitcher={true} mdxType="TokenTable" />
    <TokenTable tokenGroup="Typography" onlyShow="LetterSpacing" showTitle={true} useMultiple={true} hideSwitcher={true} mdxType="TokenTable" />
    <TokenTable tokenGroup="Typography" onlyShow="LineHeight" showTitle={true} useMultiple={true} hideSwitcher={true} mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      